import React, { useState, useEffect } from 'react';
import * as stylePc from './style.module.sass';
import * as styleSp from './style.sp.module.sass';

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return width;
};

const DivisionModal = ({ device }) => {
  const outerWidth = useWindowWidth();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const style = device === 'pc' && outerWidth > 700 ? stylePc : styleSp;
  const brForSP = device === 'pc' && outerWidth > 700 ? '' : <br />;

  return (
    <>
      <div onClick={openModal} className={style.modal__open}>
        分割料金詳細はこちら
      </div>
      {isOpen ? (
        <div className={style.modal}>
          <div className={style.modal__overflow}>
            <div className={style.modal__close} onClick={closeModal}>
              <svg className={style.modal__svg}>
                <use xlinkHref='#svg-icn_close'></use>
              </svg>
            </div>
            <h2 className={style.modal__title}>CodeCamp分割 料金詳細</h2>
            <div className={style.modal__table}>
              <div className={style.modal__tr}>
                <div className={style.modal__th}></div>
                <div className={style.modal__td}>
                  <div className={style.modal__plan}>
                    <span className={style.modal__month}>4</span>ヶ月プラン
                  </div>
                </div>
                <div className={style.modal__td}>
                  <div className={style.modal__plan}>
                    <span className={style.modal__month}>6</span>ヶ月プラン
                  </div>
                </div>
              </div>
              <div className={style.modal__tr}>
                <div className={style.modal__th}>
                  <p>
                    一括<span className={style.modal__medium}>{brForSP}（税込）</span>
                  </p>
                </div>
                <div className={style.modal__td}>
                  <span className={style.modal__num}>429,000</span>
                  <span className={style.modal__yen}>円</span>
                </div>
                <div className={style.modal__td}>
                  <span className={style.modal__num}>528,000</span>
                  <span className={style.modal__yen}>円</span>
                </div>
              </div>
              <div className={style.modal__tr}>
                <div className={style.modal__th}>
                  <div className={style.modal__background}>
                    <p>
                      6回分割<span className={style.modal__medium}>（税込）</span>
                    </p>
                  </div>
                </div>
                <div className={style.modal__td}>
                  <div className={style.modal__background}>
                    <div className={style.modal__total}>
                      <span className={style.modal__num}>441,870</span>
                      <span className={style.modal__yen}>円</span>
                      <p className={style.modal__rate}>（実質年率15.60%）</p>
                    </div>
                    <div className={style.modal__line}>
                      <p className={style.modal__label}>初期費用</p>
                      <p className={style.modal__amount}>
                        101,145<span className={style.modal__medium}>円</span>
                      </p>
                    </div>
                    <div className={style.modal__line}>
                      <p className={style.modal__label}>2回目以降</p>
                      <p className={style.modal__amount}>
                        68,145<span className={style.modal__medium}>円</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={style.modal__td}>
                  <div className={style.modal__background}>
                    <div className={style.modal__total}>
                      <span className={style.modal__num}>543,840</span>
                      <span className={style.modal__yen}>円</span>
                      <p className={style.modal__rate}>（実質年率15.36%）</p>
                    </div>
                    <div className={style.modal__line}>
                      <p className={style.modal__label}>初期費用</p>
                      <p className={style.modal__amount}>
                        118,140<span className={style.modal__medium}>円</span>
                      </p>
                    </div>
                    <div className={style.modal__line}>
                      <p className={style.modal__label}>2回目以降</p>
                      <p className={style.modal__amount}>
                        85,140<span className={style.modal__medium}>円</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.modal__tr}>
                <div className={style.modal__th}>
                  <p>
                    12回分割<span className={style.modal__medium}>（税込）</span>
                  </p>
                </div>
                <div className={style.modal__td}>
                  <div className={style.modal__total}>
                    <span className={style.modal__num}>454,740</span>
                    <span className={style.modal__yen}>円</span>
                    <p className={style.modal__rate}>（実質年率14.18%）</p>
                  </div>
                  <div className={style.modal__line}>
                    <p className={style.modal__label}>初期費用</p>
                    <p className={style.modal__amount}>
                      68,145<span className={style.modal__medium}>円</span>
                    </p>
                  </div>
                  <div className={style.modal__line}>
                    <p className={style.modal__label}>2回目以降</p>
                    <p className={style.modal__amount}>
                      35,145<span className={style.modal__medium}>円</span>
                    </p>
                  </div>
                </div>
                <div className={style.modal__td}>
                  <div className={style.modal__total}>
                    <span className={style.modal__num}>559,680</span>
                    <span className={style.modal__yen}>円</span>
                    <p className={style.modal__rate}>（実質年率13.96%）</p>
                  </div>
                  <div className={style.modal__line}>
                    <p className={style.modal__label}>初期費用</p>
                    <p className={style.modal__amount}>
                      76,890<span className={style.modal__medium}>円</span>
                    </p>
                  </div>
                  <div className={style.modal__line}>
                    <p className={style.modal__label}>2回目以降</p>
                    <p className={style.modal__amount}>
                      43,890<span className={style.modal__medium}>円</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={style.modal__tr}>
                <div className={style.modal__th}>
                  <div className={style.modal__background}>
                    <p>
                      24回分割<span className={style.modal__medium}>（税込）</span>
                    </p>
                  </div>
                </div>
                <div className={style.modal__td}>
                  <div className={style.modal__background}>
                    <div className={style.modal__total}>
                      <span className={style.modal__num}>467,610</span>
                      <span className={style.modal__yen}>円</span>
                      <p className={style.modal__rate}>（実質年率10.17%）</p>
                    </div>
                    <div className={style.modal__line}>
                      <p className={style.modal__label}>初期費用</p>
                      <p className={style.modal__amount}>
                        51,103<span className={style.modal__medium}>円</span>
                      </p>
                    </div>
                    <div className={style.modal__line}>
                      <p className={style.modal__label}>2回目以降</p>
                      <p className={style.modal__amount}>
                        18,109<span className={style.modal__medium}>円</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={style.modal__td}>
                  <div className={style.modal__background}>
                    <div className={style.modal__total}>
                      <span className={style.modal__num}>575,520</span>
                      <span className={style.modal__yen}>円</span>
                      <p className={style.modal__rate}>（実質年率10.02%）</p>
                    </div>
                    <div className={style.modal__line}>
                      <p className={style.modal__label}>初期費用</p>
                      <p className={style.modal__amount}>
                        55,605<span className={style.modal__medium}>円</span>
                      </p>
                    </div>
                    <div className={style.modal__line}>
                      <p className={style.modal__label}>2回目以降</p>
                      <p className={style.modal__amount}>
                        22,605<span className={style.modal__medium}>円</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className={style.modal__notes}>初期費用には、入学金と初回支払い分が含まれています。</p>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default DivisionModal;
